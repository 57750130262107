import React from 'react'
import './services.css'
import { BiCheck } from 'react-icons/bi'

const services = () => {
  return (
    <section>
      <h5>Yardımcı Olunan</h5>
      <h2>Hizmetler</h2>

      <div className="container services_container">
        <article className='service'>
          <div className="service_head">
            <h3>Web Tasarım</h3>
          </div>
          <ul className='services_list'>
            <li>
              <BiCheck className='services_list-icon'/>
              <h4>Landing Page</h4>
              <p> İşletmeleriniz için Web/Mobil uyumlu açılış sayfası kurulumu </p>
            </li>
            <li>
              <BiCheck className='services_list-icon'/>
              <h4>Blog sitesi</h4>
              <p>Gönderi şablonu hazır, site içi veya ayrı site olarak eklenebilen segment</p>
            </li>
            <li>
              <BiCheck className='services_list-icon'/>
              <h4>Portfolyo websitesi</h4>
              <p>Web/mobil duyarlı, eklenti destekli, hostinge yüklemeye hazır </p>
            </li>
            <li>
              <BiCheck className='services_list-icon'/>
              <h4>Restorant Menüsü</h4>
              <p> QR Kod uyumlu, Web/Mobil uyumlu, görsel destekli web sayfası </p>
            </li>
            <li>
              <BiCheck className='services_list-icon'/>
              <h4>E-ticaret sitesi</h4>
              <p>Kayıt/giriş formu, ödeme kabul sistemi, otomatik cevap veren chat bot</p>
            </li>
          </ul>
        </article>
        <article className='service'>
          <div className="service_head">
            <h3>İçerik Üretimi</h3>
          </div>
          <ul className='services_list'>
            <li>
              <BiCheck className='services_list-icon'/>
              <h4>Blog Gönderisi</h4>
              <p>Blogunuz için web geliştirme konulu yazılar</p>
            </li>
            <li>
              <BiCheck className='services_list-icon'/>
              <h4>YouTube İçeriği</h4>
              <p>Uzun, doğrudan anlatımlı ve ürün yerleşimine açık içerik metni</p>
            </li>
            <li>
              <BiCheck className='services_list-icon'/>
              <h4>Instagram Gönderisi/Reels</h4>
              <p>Görsel açıdan desteklenebilir metin yazıları</p>
            </li>
            <li>
              <BiCheck className='services_list-icon'/>
              <h4>TikTok İçeriği</h4>
              <p> Web geliştirme üretim içeriğinin tüketime hazır hale getirilmesi </p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default services