import React from 'react'
import {BsGithub} from 'react-icons/bs'
import {BsLinkedin} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'
import { FaKickstarterK } from "react-icons/fa";
import {BsYoutube} from 'react-icons/bs'
import {SiDiscord} from 'react-icons/si'

const HeaderSocials = () => {
  return (
    <div className='header_socials'>
        <a href='https://www.github.com/holmstain' target="_"><BsGithub/></a>
        <a href='https://www.linkedin.com/in/enesmucahitvaran/'target="_"><BsLinkedin/></a>
        <a href='https://www.instagram.com/holmstain'target="_"><BsInstagram/></a>
        <a href='https://www.twitter.com/holmstain'target="_"><BsTwitter/></a>
        <a href='https://www.kick.com/holmstain'target="_"><FaKickstarterK/></a>
        <a href='https://www.youtube.com/@holmstain'target="_"><BsYoutube/></a>
        <a href='https://discord.gg/dGT6gJ6JwT'target="_"><SiDiscord/></a>
    </div>
  )
}

export default HeaderSocials