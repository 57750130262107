import React from 'react';
import './about.css'
import EMV from '../../assets/emv2.jpg'
import {FaAward} from 'react-icons/fa'
import {FaUsers} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Ben Kimim</h5>
      <h2>Hakkımda</h2>

      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={EMV} alt='ME'/>
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className='about_card'>
              <FaAward className='about_icon'/>
              <h5>Tecrübe</h5>
              <small>6+ Yıllık</small>
            </article>
            <article className='about_card'>
              <FaUsers className='about_icon'/>
              <h5>Müşteri</h5>
              <small>30+ Dünya Çapında</small>
            </article>
            <article className='about_card'>
              <VscFolderLibrary className='about_icon'/>
              <h5>Projeler</h5>
              <small>60+ tamamlanmış</small>
            </article>
            </div>
            <p> Merhaba, ben Enes! <br></br>
            1997 yılında Trabzon, Türkiye'de doğdum. 2018 yılında "Gözümden Dünya" adlı bir blog kurdum. Bu blog, üniversite sınavlarına hazırlanan bireylere yardımcı olmayı ve deneyimlerimi paylaşmayı
            amaçlıyordu.
            <br></br>WordPress üzerindeki varsayılan özelliklerin ve tasarımların yetersiz olduğunu fark edince, HTML, CSS ve JavaScript öğrenmeye başladım. Ardından React.js ve Next.js kütüphaneleriyle
            web geliştirme becerilerimi geliştirdim. Node.js ile de çalışarak projelere daha ciddi bir yaklaşım benimsedim. Ayrıca, E-Ticaret site taslakları, tasarımcılar için özel portföy web siteleri,
            Bir Yapılacaklar Listesi Uygulaması ve Not Alım Uygulaması gibi çeşitli projeler tasarladım.
            <br></br>Yaklaşık 6 yıldır JavaScript ve kütüphaneleriyle çalışıyorum ve daha kapsamlı projeler için iyi
            hazırlandığımı düşünüyorum.</p>
            <a href="#contact" className='btn btn-primary'>Bana Ulaş</a>
        </div>
      </div>
    </section>
  )
}

export default About