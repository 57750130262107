import React from 'react';
import {useRef} from 'react';
import './contact.css'
import {FiMail} from 'react-icons/fi'
import {BsWhatsapp} from 'react-icons/bs'
import emailjs from '@emailjs/browser';

export const Contact = () => {
  const form = useRef();

  const sendForm = (e) => {
    e.preventDefault();

    emailjs.sendForm('portfolio_service', 'template_zdqoq1v', form.current, '9S-lQXwW0N1kGYaIU')
    e.target.reset()
    };

  return (
    <section id='contact'>
      <h5>Bana Ulaş</h5>
      <h2>İletişim</h2>
      <div className="container contact_container">
        <div className="contact_options">
          <article className='contact_option'>
            <FiMail className='contact_option-icon'/>
            <h4>Email</h4>
            <h5>jobs@holmstain.com</h5>
            <a href="mailto:jobs@holmstain.com">Email Gönder</a>
          </article>
          <article className='contact_option'>
            <BsWhatsapp className='contact_option-icon'/>
            <h4>WhatsApp</h4>
            <a href="https://api.whatsapp.com/send?phone=905350552661">Mesaj Gönder</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendForm}>
          <input type='text' name='name' placeholder='İsminiz' required/>
          <input type='email' name='email' placeholder='Mail Adresiniz' required/>
          <textarea name="message" rows="7" placeholder="Mesajınız" required/>
          <button type='submit' className='btn btn-primary'> Mesajı Gönder </button>
        </form>
      </div>
      </section>
  )
  }
export default Contact