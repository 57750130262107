import React from 'react';
import './footer.css'
import {BsGithub} from 'react-icons/bs'
import {BsLinkedin} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'
import {BsYoutube} from 'react-icons/bs'
import {FaKickstarterK} from "react-icons/fa";


const Footer = () => {
  return (
  <footer>
    <div className="footer_socials">
      <a href="https://www.github.com/holmstain" target='_'><BsGithub/></a>
      <a href="https://www.linkedin.com/in/enesmucahitvaran/" target='_'><BsLinkedin/></a>
      <a href="https://www.instagram.com/holmstain" target='_'><BsInstagram/></a>
      <a href="https://www.twitter.com/holmstain" target='_'><BsTwitter/></a>
      <a href="https://www.kick.com/holmstain" target='_'><FaKickstarterK/></a>
      <a href="https://www.youtube.com/@holmstain" target='_'><BsYoutube/></a>
    </div>
    <div className="footer_copyright">
      <small> &copy; Holmstain Co.&trade; 2024 </small>
    </div>
  </footer>
  )
}

export default Footer