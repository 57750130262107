import React from 'react';
import './experience.css'
import {BsFillPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>Kullandığım Diller</h5>
      <h2>Tecrübelerim</h2>
      <div className="container experience_container">
        <div className="experience_frontend">
          <h3>Frontend Geliştirme</h3>
          <div className="experience_content">
            <article className='experience_details'>
              <BsFillPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>HTML5</h4><small className='text-light'>Üst Düzey</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsFillPatchCheckFill className='experience_details-icon' />
              <div>
                <h4>CSS3</h4><small className='text-light'>Üst Düzey</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsFillPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>JavaScript</h4><small className='text-light'>Üst Düzey</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsFillPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>React</h4><small className='text-light'>Üst Düzey</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsFillPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>Bootstrap</h4><small className='text-light'>Üst Düzey</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsFillPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>Tailwind</h4><small className='text-light'>Üst Düzey</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience_backend">
          <h3>Backend Geliştirme</h3>
          <div className="experience_content">
          <article className='experience_details'>
              <BsFillPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>Firebase</h4><small className='text-light'>Üst Düzey</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsFillPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>Node.js</h4><small className='text-light'>Üst Düzey</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsFillPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>MongoDB</h4><small className='text-light'>Üst Düzey</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsFillPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>PHP</h4><small className='text-light'>Giriş Düzeyi</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsFillPatchCheckFill className='experience_details-icon'/>
              <div>
                <h4>Pyhton</h4><small className='text-light'>Giriş Düzeyi</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience