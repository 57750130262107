import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/holm-ecommerce.jpg'
import IMG2 from '../../assets/holm-notes.jpg'
import IMG3 from '../../assets/holm-todo.jpg'
import IMG4 from '../../assets/holm-chat.png'
import IMG5 from '../../assets/burger-house.png'



const Portfolio = () => {
  return (
    <section id="portfolio">
        <h5>İşlerimden Bazıları</h5>
        <h2>Portolyom</h2>

        <div className="container portfolio_container">
          <article className='portfolio_item'>
            <div className="portfolio_item-image">
              <img src={IMG1} alt="Project-image-1" />
            </div>
            <h3>E-ticaret Sitesi (Taslak)</h3>
            <div className="portfolio_item-cta">
              <a href='https://www.github.com/holmstain' className='btn' target="_">GitHub</a>
              <a href='https://ecommerce.holmstain.com' className='btn btn-primary' target="_">Demo</a>
            </div>
          </article>
          <article className='portfolio_item'>
            <div className="portfolio_item-image">
              <img src={IMG2} alt="Project-image-2" />
            </div>
            <h3>Holm Notes</h3>
            <div className="portfolio_item-cta">
              <a href='https://www.github.com/holmstain' className='btn' target="_">GitHub</a>
              <a href='https://notes.holmstain.com/' className='btn btn-primary' target="_">Demo</a>
            </div>
          </article>
          <article className='portfolio_item'>
            <div className="portfolio_item-image">
              <img src={IMG3} alt="Project-image-3" />
            </div>
            <h3>Holm To Do</h3>
            <div className="portfolio_item-cta">
              <a href='https://www.github.com/holmstain' className='btn' target="_">GitHub</a>
              <a href='https://todo-app.holmstain.com' className='btn btn-primary' target="_">Demo</a>
            </div>
          </article>
          <article className='portfolio_item'>
            <div className="portfolio_item-image">
              <img src={IMG4} alt="Project-image-3" />
            </div>
            <h3>Holm Chat</h3>
            <div className="portfolio_item-cta">
              <a href='https://www.github.com/holmstain' className='btn' target="_">GitHub</a>
              <a href='https://chat.holmstain.com' className='btn btn-primary' target="_">Demo</a>
            </div>
          </article>
          <article className='portfolio_item'>
            <div className="portfolio_item-image">
              <img src={IMG5} alt="Project-image-3" />
            </div>
            <h3>Holm Burger</h3>
            <div className="portfolio_item-cta">
              <a href='https://www.github.com/holmstain' className='btn' target="_">GitHub</a>
              <a href='https://burger.holmstain.com' className='btn btn-primary' target="_">Demo</a>
            </div>
          </article>
        </div>
    </section>
  )
}

export default Portfolio