import React from 'react';
import './header.css';
import HeaderSocials from './HeaderSocials';
import CTA from './CTA'
import EMV from '../../assets/emv.png'

const Header = () => {
  return (
    <header>
      <div className="container header_container">
        <h5 className='merhaba'>Merhaba!</h5>
        <h1>Enes Mücahit</h1>
        <h5 className='text-light'> Front End Developer </h5>
        <CTA />
        <HeaderSocials />

        <div className="me">
          <img src={EMV} alt="me"/>
        </div>
        <a href='#contact' className='scroll_down'>Derine İn</a>
      </div>
    </header>
  )
}

export default Header